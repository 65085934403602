<template>
    <div>
        <v-container class="a-container">
            <div :class="mobileCentering" class="display-1 primary-text font-weight-bold font-weight-light mb-2 mt-3">Statements</div>
            <v-divider></v-divider>
            <documents-table document-type="statements"></documents-table>
        </v-container>
    </div>
</template>

<script>
    import DocumentsTable from '../../components/documents/DocumentsTable'

    export default {
        name: "Statements",
        components: {DocumentsTable},
        computed: {
             isMobile(){
                return this.$vuetify.breakpoint.mdAndDown
            },

            mobileCentering(){
                return this.isMobile ? 'text-center' : ''
            },

            mobileFilterCentering(){
                return this.isMobile ? 'center' : null
            },
        }
    }
</script>

<style scoped>

</style>